import axios from "axios";

export function fileNameFromUrl(url) {
    return decodeURIComponent(url).replace(/[\#\?].*$/,'').split('/').pop();
}
export function download(url){
    const fileName = fileNameFromUrl(url);
    axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        if (!window.navigator.msSaveOrOpenBlob){
            // BLOB NAVIGATOR
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        }else{
            // BLOB FOR EXPLORER 11
            const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]),fileName);
        }
    });
}