<template>
    <div id="contents" class="contents vmd_add_page" v-if="vmd">

        <div class="content_head">
            <!-- s: 페이지 타이틀 -->
          <h2 class="page_ttl" v-if="pageMode !== 'modify'">태블릿 VMD 등록</h2>
          <h2 class="page_ttl" v-if="pageMode === 'modify'">태블릿 VMD 수정</h2>
            <!-- e: 페이지 타이틀 -->
        </div>

        <!-- s: content_body -->
        <div class="content_body">
            <!-- s: input_sec -->
            <div class="input_sec type_nobg">
                <div class="template_type_wrap is_label_cont">
                    <div class="label_cont">
                        <p>템플릿 타입</p>
                    </div>
                    <div class="content_wrap">
                        <div class="input_area radio inline">
                            <label></label>
                            <div class="input_box" v-for="(item, key) in vmdTypes">
                                <label>
                                    <input type="radio"
                                           :value="item.code"
                                           v-model="vmd.templateType"
                                           @click="changeTemplate(item)">
                                    <span>{{item.name}}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="board_sec">
                <div class="cont_ttl_area">
                    <h3>기본 정보</h3>
                </div>
                <!-- s: table -->
                <div class="tbl_wrap list form">
                    <table class="tbl">
                        <caption></caption>
                        <tbody>
                        <tr>
                            <th class="must_txt">제품명</th>
                            <td>
                                <div :class="`input_area inline ${!validation.name ? 'is_error' : ''}`">
                                    <label></label>
                                    <div class="input_box">
                                        <input type="text" id="ttl" v-model="vmd.name" @keyup="validator('name', 'string')">
                                    </div>
                                    <small v-if="!validation.name" class="error_txt">제품명을 입력 해 주세요.</small>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="permit(vmdTypes.HOT_DEAL, vmdTypes.HALF_PRICE)">
                            <th class="">부제목</th>
                            <td>
                                <div class="input_area inline">
                                    <label></label>
                                    <div class="input_box">
                                        <input type="text" id="subTtl" v-model="vmd.subName">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="permit(vmdTypes.HOT_DEAL, vmdTypes.HALF_PRICE)">
                            <!-- Dev: must_txt 삭제 210803-->
                            <th>제품설명</th>
                            <td>
                                <div :class="`input_area text multiple ${!vmd.desc ? 'is_error' : ''}`">
                                    <label></label>
                                    <!-- Dev: 210803 textarea > input 3개로 변경-->
                                    <div class="input_box">
                                        <input type="text" v-model="vmd.desc[0]">
                                    </div>
                                    <div class="input_box">
                                        <input type="text" v-model="vmd.desc[1]">
                                    </div>
                                    <div class="input_box">
                                        <input type="text" v-model="vmd.desc[2]">
                                    </div>
                                    <!--                    <textarea name="" id="info" cols="30" rows="10" v-model="vmd.desc"></textarea>-->
                                    <small v-if="!vmd.desc" class="error_txt">제품설명을 입력 해 주세요.</small>
                                </div>
                            </td>
                        </tr>
                        <!--Dev s: 우리매장 핫딜 가격 항목 변경으로 인한 추가 210915-->
                        <tr v-if="permit(vmdTypes.HOT_DEAL)">
                          <th class="must_txt">소비자가</th>
                          <td>
                            <div :class="`input_area inline ${!validation.retailPrice ? 'is_error' : ''}`">
                              <label></label>
                              <div class="input_box">
                                <cleave v-model.number="vmd.retailPrice"
                                        :options="getCleaveOption('retailPrice', 'positiveNumber')"/>
                              </div>
                              <div class="input_txt">원</div>
                              <div v-if="!validation.retailPrice" class="error_txt">소비자가를 입력해주세요.</div>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="permit(vmdTypes.HOT_DEAL)">
                          <th>할인가</th>
                          <td>
                            <div class="input_area inline">
                              <label></label>
                              <div class="input_box">
                                <input type="text" v-model="vmd.discountedPrice">
                              </div>
                              <div class="input_txt">원</div>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="permit(vmdTypes.HOT_DEAL)">
                          <th class="must_txt">멤버십 할인가</th>
                          <td>
                            <div :class="`input_area inline ${!validation.membershipPrice ? 'is_error' : ''}`">
                              <label></label>
                              <div class="input_box">
                                <cleave v-model.number="vmd.membershipPrice"
                                        :options="getCleaveOption('membershipPrice', 'positiveNumber')"/>
                              </div>
                              <div class="input_txt">원</div>
                              <div v-if="!validation.membershipPrice" class="error_txt">멤버십 할인가를 입력해주세요.</div>
                            </div>
                          </td>
                        </tr>
                        <!--Dev e: 우리매장 핫딜 가격 항목 변경으로 인한 추가 210915-->

                        <tr v-if="permit(vmdTypes.HALF_PRICE)">
                            <th class="must_txt">정상가</th>
                            <td>
                                <div :class="`input_area inline ${!validation.normalPrice ? 'is_error' : ''}`">
                                    <label></label>
                                    <div class="input_box">
                                        <cleave v-model.number="vmd.normalPrice"
                                                :options="getCleaveOption('normalPrice', 'positiveNumber')"/>
<!--                                        <input type="number" id="price" v-model="vmd.normalPrice"
                                               @keypress="onlyNumber($event)"
                                               @keyup="validator('normalPrice', 'positiveNumber', $event)">-->
                                    </div>
                                    <div class="input_txt">원</div>
                                    <div v-if="!validation.normalPrice" class="error_txt">정상가를 입력해주세요.</div>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="permit(vmdTypes.HALF_PRICE)">
                            <th class="must_txt">일시납</th>
                            <td>
                                <div :class="`input_area inline ${!validation.temporaryPrice ? 'is_error' : ''}`">
                                    <label></label>
                                    <div class="input_box">
                                        <cleave v-model.number="vmd.temporaryPrice"
                                                :options="getCleaveOption('temporaryPrice', 'positiveNumber')"/>
<!--                                        <input type="number" id="payDirect" v-model="vmd.temporaryPrice"
                                               @keypress="onlyNumber($event)"
                                               @keyup="validator('temporaryPrice', 'positiveNumber', $event)">-->
                                    </div>
                                    <div class="input_txt">원</div>
                                    <div v-if="!validation.temporaryPrice" class="error_txt">일시납을 입력해주세요.</div>
                                </div>
                                <div class="input_area checkbox inline">
                                    <div class="input_box">
                                        <label>
                                            <input type="checkbox" id="free">
                                            <span>무료</span>
                                        </label>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="permit(vmdTypes.HALF_PRICE)">
                            <!-- Dev: 할부납 필수요소 아님 > must_txt 클래스 삭제 210804-->
                            <th>할부납</th>
                            <td>
                                <div class="input_area select inline">
                                    <select v-model="vmd.installmentMonth">
                                        <option value="12">12개월</option>
                                        <option value="24">24개월</option>
                                        <option value="36">36개월</option>
                                    </select>
                                </div>
                                <div class="input_area inline">
                                    <label></label>
                                    <div class="input_box">
                                        <input type="number" v-model="vmd.installmentPrice"> </div>
                                    <div class="input_txt">원</div>
<!--                                    <div v-if="!isNumeric(vmd.installmentPrice)" class="error_txt">혜택가를 입력해주세요.</div>-->
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <!-- e: table -->
            </div>
            <!-- e: board_sec -->
            <!-- s: file_sec -->
            <div class="file_sec">
                <div class="cont_ttl_area">
                    <h3>이미지(영상) 슬라이드</h3>
                </div>
                <div class="bg_box">
                    <ul>
                        <li>
                            <span>이미지 파일형식 JPG, GIF, PNG</span>
                            <span>용량 2MB 이하</span>
                            <span>해상도 : 우리매장 핫딜, 반값초이스 1,000*1,000 / 만능체험대 2,000*1,200</span>
                        </li>
                        <li>
                            <span>영상 파일형식 MP4 </span>
                            <span>용량 50MB 이하</span>
                            <span>해상도 1920*1080</span>
                        </li>
                    </ul>
                </div>
                <div class="upload_area clearFix">
                    <!-- 사진 없을때 -->
                    <!-- Dev: 파일 첨부 후 유형 video일 경우 video 클래스 추가 210802 -->
                    <div v-for="(file, index) in vmd.files"
                         :class="`upload_box flL ${!file ? 'is_error' : ''} ${isVideo(file) ? 'video' : ''}`">
                        <div v-if="file" class="upload_btn has_file" role="button">
                            <figure v-if="isImage(file)" class="uploaded_img">
                                <img :src="getFile(file)" alt="">
                            </figure>
                            <input type="file" @change="onFileChange($event, index)"
                                   accept="image/gif,image/jpeg,image/png,video/mp4"
                                   @load="onFileLoad($event, index)"
                            >
                            <!-- Dev: 삭제, 다운로드 버튼 추가 210803-->
                            <div class="btn_area">
                                <!-- Dev : 다운로드, 삭제 버튼 순서 변경 210804 -->
                                <!-- Dev : 다운로드 버튼은 수정 페이지에서만 보이게 해주세요 210804-->
                                <button v-if="pageMode === 'modify'" type="button" class="btn w_s h_s basic"
                                        @click="download(getFile(file))">다운로드
                                </button>
                                <button type="button" class="btn w_s h_s basic" @click="deletePreview($event, index)">
                                    삭제
                                </button>
                            </div>
                            <!-- Dev: 파일명 수정모드일때도 나오게 해주세요 210915-->
                            <p class="filename" v-if="typeof file === 'string'">{{ fileNameFromUrl(vmd.files[index]) }}</p>
                            <p class="filename" v-else>{{ vmd.files[index].name }}</p>
                        </div>
                        <div class="upload_btn" role="button" v-else-if="!file">
                            <div class="txt_area">
                                <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                            </div>
                            <div class="btn_area">
                                <button type="button" class="btn basic w_s h_s">파일추가</button>
                            </div>
                            <input type="file" @change="onFileChange($event, index)" accept=".gif,.jpeg,.jpg,.png,.mp4">
                        </div>
                        <small v-else-if="!file" class="error_txt">파일을 선택 해 주세요.</small>
                    </div>
                    <!--                    <div class="upload_box flL">
                                            <div class="upload_btn" role="button">
                                                <div class="txt_area">
                                                    <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                                                </div>
                                                <div class="btn_area">
                                                    <button type="button" class="btn basic w_s h_s">파일추가</button>
                                                </div>
                                                <input type="file" name="productPic" id="upload02">
                                            </div>
                                        </div>
                                        <div class="upload_box flL">
                                            <div class="upload_btn" role="button">
                                                <div class="txt_area">
                                                    <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                                                </div>
                                                <div class="btn_area">
                                                    <button type="button" class="btn basic w_s h_s">파일추가</button>
                                                </div>
                                                <input type="file" name="productPic" id="upload03">
                                            </div>
                                        </div>-->
                    <!-- 사진 있을때 -->
                    <!--                    <div class="upload_box flL">
                                            <div class="upload_btn" role="button">
                                                <figure class="uploaded_img">
                                                    <img src="/kt/resources/img/test.jpg" alt="">
                                                </figure>
                                            </div>
                                        </div>-->
                </div>
            </div>
            <!-- e: file_sec -->
            <!-- s: timer_sec -->
            <div class="timer_sec">
                <!-- s: table -->
                <div class="tbl_wrap list form">
                    <table class="tbl">
                        <caption></caption>
                        <tbody>
                        <tr>
                            <th class="must_txt">이미지 슬라이드 시간</th>
                            <td>
                                <div :class="`input_area inline ${!vmd.imagePlayTime ? 'is_error' : ''}`">
                                    <label></label>
                                    <div class="input_box">
                                        <input type="number" v-model.number="vmd.imagePlayTime">
                                        <div class="input_txt">초</div>
                                    </div>
                                    <div v-if="!vmd.imagePlayTime" class="error_txt">슬라이드 시간을 입력 해 주세요.</div>
                                </div>
                                <div class="input_area inline playtime">
<!--                                    <p v-if="vmd.filesPlayTime > 0">총 재생 시간 : {{vmd.filesPlayTime}}</p>-->
                                    <p>총 재생 시간 : {{vmdPlayTime.toFixed(2)}}</p>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <!-- e: table -->
            </div>
            <!-- e: timer_sec -->
        </div>
        <!-- e: content_body -->

        <!-- s: content_foot -->
        <div class="content_foot">
            <div class="btn_group center">
                <button class="btn basic w_l h_m" @click="openModal">미리보기</button>
                <button class="btn basic w_l h_m" @click="redirect('/vmd')">취소</button>
                <button v-if="pageMode === 'modify'" class="btn basic w_l h_m" @click="confirmDialog()">삭제</button>
                <button v-if="pageMode === 'modify'" class="btn strong w_l h_m" @click="modify()">수정</button>
                <button v-else class="btn strong w_l h_m" @click="create()">저장</button>
            </div>
        </div>
        <!-- e: content_foot -->
    </div>
    <!-- Dev s: 미리보기 모달 추가 210802 -->
    <div class="modal_wrap preview landscape" ref="previewModal" v-show="isShowPreviewModal">
        <div class="modal"
             :class="permit(vmdTypes.HOT_DEAL, vmdTypes.HALF_PRICE) ? 'type1' : 'type2'">
            <button type="button" class="close_modal" @click="closeModal('previewModal')"></button>
            <!-- Dev: 가로모드만 지원해서 라디오버튼 삭제 210806
            <div class="modal_head">
                <div class="input_area multiple inline radio">
                    <div class="input_box">
                        <label>
                            <input type="radio" name="displayType" @change="checkDirection('portrait')" checked>
                            <span>세로</span>
                        </label>
                    </div>
                    <div class="input_box">
                        <label>
                            <input type="radio" name="displayType" @change="checkDirection('landscape')"
                                   data-dir="landscape">
                            <span>가로</span>
                        </label>
                    </div>
                </div>
            </div>-->
            <div class="modal_body">
                <div class="display_wrap">
                    <!-- Dev: 우리매장 핫딜/반값초이스 일 경우 -->
                    <div class="info_area" v-if="permit(vmdTypes.HOT_DEAL, vmdTypes.HALF_PRICE)">
                        <div class="ttl_box">
                            <p class="ttl">{{ vmd.name }}</p>
                        </div>
                        <div class="info_box">
                            <p class="desc" v-if="vmd.subName.trim().length > 0">{{ vmd.subName }}</p>
                            <dl v-if="permit(vmdTypes.HOT_DEAL)" class="info_hotdeal">
                                <div class="retail_price">
                                  <dt>소비자가</dt>
                                  <dd>
                                    <strong>{{ printAsPrice(vmd.retailPrice) }}</strong>원
                                  </dd>
                                </div>
                                <div v-if="vmd.discountedPrice">
                                  <dt>할인가</dt>
                                  <dd>
                                    <strong>{{ printAsPrice(vmd.discountedPrice) }}</strong>원
                                  </dd>
                                </div>
                                <div class="membership_price">
                                  <dt>멤버십 할인가</dt>
                                  <dd class="strong">
                                    <strong>{{ printAsPrice(vmd.membershipPrice) }}</strong>원
                                  </dd>
                                </div>
                              </dl>
                              <dl v-if="permit(vmdTypes.HALF_PRICE)" class="info_halfprice">
                                <div class="normal">
                                    <dt>정상가</dt>
                                    <dd><b>{{ printAsPrice(vmd.normalPrice) }}</b>원</dd>
                                </div>
                                <div>
                                    <dt>일시납</dt>
                                    <dd class="strong">
                                        <span>인터넷/TV 가입 시</span>
                                        <strong>{{ printAsPrice(vmd.temporaryPrice) }}</strong>원
                                    </dd>
                                </div>
                                <div v-if="vmd.installmentPrice">
                                    <dt>할부납(36개월)</dt>
                                    <dd class="strong">월 <strong>{{ printAsPrice(vmd.installmentPrice) }}</strong>원</dd>
                                </div>
                            </dl>
                        </div>
                        <div class="features_box" v-if="hasDesc">
                            <p>제품 설명</p>
                            <ul>
                                <li v-if="vmd.desc[0].trim().length > 0">{{ vmd.desc[0] }}</li>
                                <li v-if="vmd.desc[1].trim().length > 0">{{ vmd.desc[1] }}</li>
                                <li v-if="vmd.desc[2].trim().length > 0">{{ vmd.desc[2] }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="img_area">
                      <div class="swiper-pagination"></div>
                      <swiper
                          class="preview_img"
                          :pagination="{ el: '.swiper-pagination' }"
                          :space-between="30"
                          @swiper="setControlledSwiper"
                          :autoplay="{
                            'delay': vmd.imagePlayTime * 1000
                          }"
                      >
                        <swiper-slide v-if="vmd.files" v-for="(file, index) in vmd.files.filter((file) => file)">
                          <!--Dev: 첨부된 이미지 백그라운드로 넣어주세요 210803-->
                          <div v-if="isImage(file)" class="thumb_box"
                               :style="`background-image: url('${getFile(file)}')`"></div>
                          <!--Dev:  video일 경우-->
                          <div v-if="isVideo(file)" class="thumb_box video">
                            <video :src="getFile(file)" controls></video>
                          </div>
                        </swiper-slide>
                        <!--                            <swiper-slide>
                                                        &lt;!&ndash; Dev: 첨부된 이미지 백그라운드로 넣어주세요 210803&ndash;&gt;
                                                        <div class="thumb_box" style="background-image: url('');"></div>
                                                    </swiper-slide>
                                                    <swiper-slide>
                                                        &lt;!&ndash; Dev: video일 경우 &ndash;&gt;
                                                        <div class="thumb_box video">
                                                            <video src="https://file-examples-com.github.io/uploads/2017/04/file_example_MP4_1920_18MG.mp4"
                                                                   controls></video>
                                                        </div>
                                                    </swiper-slide>
                                                    <swiper-slide>
                                                        <div class="thumb_box" style="background-image: url();"></div>
                                                    </swiper-slide>-->
                      </swiper>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Dev e: 미리보기 모달 추가 210802 -->

    <!-- Dev s: 다이어로그 Yes/No 추가 210805 -->
    <Dialog :isOpen="createDialogState" :yes="'등록'" :cancel="'취소'" v-on:cancelDialog="createDialogState = false" v-on:yesDialog="createVmd()">
        <template #body>
            <h3>등록 확인</h3>
            <p>등록 하시겠습니까?</p>
        </template>
    </Dialog>
    <Dialog :isOpen="updateDialogState" :yes="'등록'" :cancel="'취소'" v-on:cancelDialog="updateDialogState = false" v-on:yesDialog="updateVmd()">
        <template #body>
            <h3>수정 확인</h3>
            <p>수정 하시겠습니까?</p>
        </template>
    </Dialog>
    <!-- Dev e: 다이어로그 Yes/No 추가 210805 -->
    <Dialog :isOpen="deleteDialogState" :yes="'삭제'" :cancel="'취소'" v-on:cancelDialog="deleteDialogState = false" @yesDialog="deleteVmd()" :disabled="modifyChannel.length > 0">      <template #body>
      <h3>삭제 확인</h3>
      <p>VMD를 삭제 하시겠습니까?</p>
      </template>
    </Dialog>
</template>

<script>
import Dialog from "@/components/dialog";
import {getTemplate, TEMPLATE} from '@/plugins/firebase/type';
import HotDeal from "@/plugins/firebase/model/vmd/hotdeal";
import HalfPrice from "@/plugins/firebase/model/vmd/halfprice";
import Explore from "@/plugins/firebase/model/vmd/explore";
import {download, fileNameFromUrl} from "@/plugins/network";
import moment from "moment";
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/swiper.min.css';
import "swiper/components/pagination/pagination.min.css";
import SwiperCore, {Autoplay, Pagination} from 'swiper/core';
import getBlobDuration from 'get-blob-duration';
import Cleave from 'vue-cleave-component';

SwiperCore.use([Autoplay, Pagination]);

export default {
    name: "index",
    layout: {
        name: 'admin',
    },
    components: {
        Cleave,
        Swiper,
        SwiperSlide,
        Dialog
    },
    computed: {
        hasDesc() {
            const desFiltered = this.vmd.desc.filter(item => item.trim().length > 0) || [];
            return desFiltered.length !== 0;
        },
    },
    data() {
        return {
            pageMode: '',
            modifyHash: '',
            modifyChannel: '',
            vmdTypes: TEMPLATE,
            vmd_root_key: 'vmd',
            channel_root_key: 'channel',
            vmd: null,
            tempVmdData: [],
            vmdPlayTime: 0,
            currentVmdType: TEMPLATE.HOT_DEAL.code,
            isShowPreviewModal: false,
            controlledSwiper: null,
            validation:{
                name:true,
                normalPrice: true,
                specialPrice: true,
                temporaryPrice: true,
                retailPrice: true,
                discountedPrice: true,
                membershipPrice: true

            },
            createDialogState: false,
            updateDialogState: false,
            deleteDialogState: false,
            cleaveOptions:{
                // prefix: '￦',
                numeral: true,
                numeralPositiveOnly: true,
                numeralThousandsGroupStyle: 'thousand',
                noImmediatePrefix: true,
                rawValueTrimPrefix: true,
                numericOnly: true,
            },
        }
    },
    methods: {
        fileNameFromUrl: fileNameFromUrl,
        getCleaveOption(key, type){
            const copy = _.cloneDeep(this.cleaveOptions);
            const paste = {
                onValueChanged: (e) => {
                    this.validator(key, type, e);
                },
            };
            Object.assign(copy, paste);
            return copy;
        },
        setControlledSwiper(swiper) {
            this.controlledSwiper = swiper;
        },
        changeTemplate(template) {
            let prev = this.vmd;
            let next = this.tempVmdData[template.code];
            next.setDefaultInfo(prev);
            this.currentVmdType = template.code;
            this.vmd = next;
        },
        permit(...type) {
            return type.find(x => x.code === this.currentVmdType);
        },
        onFileChange(e, index) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            let file = files[0];

            const isImage = this.isImage(file);
            const isVideo = this.isVideo(file);
            const maxImageSize = 2 * 1024 * 1024;
            const maxVideoSize = 80 * 1024 * 1024;
            if (!isImage && !isVideo) {
                this.toastDanger('이미지는 .jpg, .gif, .png, 동영상은 .mp4만 지원합니다.');
                return;
            }
            if (isImage) {
                if (maxImageSize < file.size) {
                    this.toastDanger('이미지 용량은 2MB 미만으로 등록해주세요.');
                    return;
                }
            } else if (isVideo) {
                const existsVideo = this.vmd.files.find((file) => {
                    if (file instanceof File) {
                        if(this.isVideo(file)){
                            return true;
                        }
                    } else if (typeof file === 'string') {
                        if(file.toLowerCase().indexOf('.mp4') >= 0){
                            return true;
                        }
                    }
                    return false;
                });
                /*if(existsVideo){
                    this.toastDanger('동영상은 최대 1개만 등록 가능합니다.');
                    return;
                }*/
                if (maxVideoSize < file.size) {
                    this.toastDanger('동영상 용량은 80MB 미만으로 등록해주세요.');
                    return;
                }
            }
            this.vmd.files[index] = file;
            this.countPlayTime();
        },
        countPlayTime() {
            this.vmdPlayTime = 0;
            if (this.vmd && this.vmd.files) {
                for (const file of this.vmd.files) {
                    const isVideo = this.isVideo(file);
                    const isImage = this.isImage(file);
                    if(isVideo){
                        try{
                            const blob = URL.createObjectURL(file);
                            getBlobDuration(blob).then((i) => {
                                this.vmdPlayTime += i;
                                console.log(i, '더햇음', this.vmdPlayTime);
                            });
                        }catch (ignore){
                            fetch(file).then(res => res.blob()).then(blob => {
                                getBlobDuration(blob).then((i) => {
                                    this.vmdPlayTime += i;
                                });
                            });
                        }
                    }
                    else if(isImage){
                        this.vmdPlayTime += this.vmd.imagePlayTime;
                    }else{
                        if(file){
                            //console.log(file);
                            const url = file.toString().toLowerCase();
                            if(url.indexOf('.mp4')){
                                try{
                                    const blob = URL.createObjectURL(file);
                                    getBlobDuration(blob).then((i) => {
                                        this.vmdPlayTime += i;
                                    });
                                }catch (ignore){
                                    fetch(file).then(res => res.blob()).then(blob => {
                                        getBlobDuration(blob).then((i) => {
                                            this.vmdPlayTime += i;
                                        });
                                    });
                                }

                            }else{
                                this.vmdPlayTime += this.vmd.imagePlayTime;
                            }
                        }
                    }
                }
            }
        },
        deletePreview(e, index) {
            this.vmd.files[index] = null;
            this.countPlayTime();
        },
        download(url) {
            download(url);
        },
        async upload(hash, image, current, total) {
            let storageRef = this.$firebase.storage().ref();
            let imageRef = storageRef.child(hash).child(image.name);
            let uploadTask = imageRef.put(image);
            return new Promise(((resolve, reject) => {
                uploadTask.on('state_changed', (snapshot) => {
                    let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    const gage = (Math.floor(100 / total) * current) + (Math.floor(progress) / total);
                    this.$Progress.set(gage);
                    //console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case this.$firebase.storage.TaskState.PAUSED: // or 'paused'
                            //console.log('Upload is paused');
                            break;
                        case this.$firebase.storage.TaskState.RUNNING: // or 'running'
                            //console.log('Upload is running');
                            break;
                    }
                }, (error) => {
                    console.error('[ERROR] state_changed', error);
                    reject(error);
                }, () => {
                    // 완료 다운로드 예시
                    resolve(uploadTask.snapshot.ref.getDownloadURL());
                });
            }));
        },
        modify() {
            if(!this.anyFileExists()){
                this.toastDanger('최소 하나의 파일이 등록되어야 합니다');
                return;
            }
            this.updateDialogState = true;
        },
        async updateVmd(){
            this.createDialogState = false;
            let vmd = this.vmd;
            vmd.lastModifiedDateTime = this.timeToString(moment());
            await this.$firebase.database().ref(this.vmd_root_key).child(vmd.hash).set(vmd);
            if (vmd.files && vmd.files.length > 0) {
                let availables = vmd.files;
                if (availables.length > 0) {
                    vmd.filesPlayTime = 0;
                    this.$Progress.start();
                    for await (const [index, file] of availables.entries()) {
                        vmd.filesPlayTime += await this.getFileDuration(file);
                        if (file && file instanceof File) {
                            let downloadUrl = await this.upload(vmd.hash, file, index, availables.length);
                            vmd.files[index] = downloadUrl;
                        }
                    }
                    if(vmd.filesPlayTime){
                        vmd.filesPlayTime = Number(vmd.filesPlayTime.toFixed(2));
                    }
                    this.$Progress.finish();
                    vmd.lastModifiedDateTime = this.timeToString(moment());
                    this.$firebase.database().ref(this.vmd_root_key).child(vmd.hash).set(vmd).then(() => {
                        this.toastSuccess('수정 완료.');
                        this.redirect('/vmd');
                    });
                }
            }
        },
        create() {
            const validation = this.vmd.validate();
            if(validation && Array.isArray(validation) && validation.length > 0){
                validation.forEach((val) => {
                    //console.log(val);
                    this.validator(val.key, val.scheme.type);
                });
                this.toastDanger(validation.map((val) => {
                    return val.scheme.msg
                }).join('<br>'));
                return;
            }

            if(!this.anyFileExists()){
                this.toastDanger('최소 하나의 파일이 등록되어야 합니다');
                return;
            }

            this.createDialogState = true;

        },
        async createVmd(){
            this.createDialogState = false;
            let instance = _.cloneDeep(this.vmd);
            instance.files = [];
            instance.creationDateTime = this.timeToString(moment());
            instance.lastModifiedDateTime = this.timeToString(moment());
            let hash = this.$firebase.database().ref(this.vmd_root_key).push(instance).key;
            if (this.vmd.files && this.vmd.files.length > 0) {
                let availables = this.vmd.files.filter(x => x && typeof x !== 'undefined');
                if (availables.length > 0) {
                    this.$Progress.start();
                    for await (const file of availables) {
                        if (file) {
                            const index = availables.indexOf(file);
                            let downloadUrl = await this.upload(hash, file, index, availables.length);
                            instance.files[index] = downloadUrl;
                            instance.filesPlayTime += await this.getFileDuration(file);
                        }
                    }
                    if(instance.filesPlayTime){
                        instance.filesPlayTime = Number(instance.filesPlayTime.toFixed(2));
                    }
                    this.$Progress.finish();
                    instance.lastModifiedDateTime = this.timeToString(moment());
                    this.$firebase.database().ref(this.vmd_root_key).child(hash).set(instance).then(() => {
                        this.toastSuccess('등록 완료.');
                        this.redirect('/vmd');
                    });

                }
            }
        },
        async getFileDuration(file){
            const isVideo = this.isVideo(file);
            const isImage = this.isImage(file);
            if(isVideo){
                const blob = URL.createObjectURL(file);
                return await getBlobDuration(blob);
            }
            else if(isImage){
                return this.vmd.imagePlayTime;
            }else{
                if(file){
                    //console.log(file);
                    const url = file.toString().toLowerCase();
                    if(url.indexOf('.mp4')){
                        const blob = URL.createObjectURL(file);
                        return await getBlobDuration(blob);
                    }else{
                        return this.vmd.imagePlayTime;
                    }
                }
            }
            return 0;
        },
        anyFileExists(){
            return this.vmd.files.map((file) => {
                return this.getFile(file) != null;
            }).some((check) => check === true);
        },
        openModal() {
          // Dev:210813 첨부파일 1개일 경우 swiper 안되게 수정
          let files = this.vmd.files;
          let filesLth = 0;
          for(var i=0; i<files.length; i++){
            if(files[i] !== null){
              filesLth++;
            }
          }
          if(this.anyFileExists()){
              this.isShowPreviewModal = true;
              if(filesLth >= 2){
                //console.log('2개이상')
                this.$nextTick(() => {
                  this.controlledSwiper.update();
                  this.controlledSwiper.enable();
                })
              }else{
                //console.log('1개')
                this.$nextTick(() => {
                  this.controlledSwiper.disable();
                })
              }
              document.documentElement.style.overflow = 'hidden';
          }else{
              this.toastDanger('최소 하나의 파일이 등록되어야 합니다.');
          }

        },
        closeModal() {
            this.isShowPreviewModal = false;
            document.documentElement.style.overflow = ''
        },
        onlyNumber(event){
            event = (event) ? event : window.event;
            const charCode = (event.which) ? event.which : event.keyCode;
            //console.log(charCode);
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                event.preventDefault();
            } else {
                return true;
            }
        },
        validator(key, type){
            if(type === 'string'){
                this.validation[key] = this.vmd[key].length > 0;
            }else if(type === 'positiveNumber'){
                this.validation[key] = this.isNumeric(this.vmd[key]) && Number(this.vmd[key]) >= 0;
            }
            return this.validation[key] ? this.validation[key] : false;
        },
        cleaveChanged(e){
            this.validator('normalPrice', 'positiveNumber', e);
        },
        confirmDialog(){
            this.deleteDialogState = true;
        },
        deleteVmd(){
            const ref = this.$firebase.database().ref(this.vmd_root_key);
            ref.child(this.vmd.hash).remove();
            this.deleteDialogState = false;
            this.redirect('/vmd');
        },
    },
    created() {
        let fileNames = [];
        this.vmd = new HotDeal();
        if (this.$route.meta.mode) {
            this.pageMode = this.$route.meta.mode;
            this.modifyHash = this.$route.params.hash;
            const ref = this.$firebase.database().ref(this.vmd_root_key);
            ref.child(this.modifyHash).get().then((snapshot) => {
                if (snapshot.exists()) {
                    const vmd = snapshot.val() || {};
                    this.tempVmdData[TEMPLATE.HOT_DEAL.code] = new HotDeal();
                    this.tempVmdData[TEMPLATE.HALF_PRICE.code] = new HalfPrice();
                    this.tempVmdData[TEMPLATE.EXPLORE.code] = new Explore();
                    this.tempVmdData[vmd.templateType].setDefaultInfo(vmd);
                    this.vmd = this.tempVmdData[vmd.templateType];
                    this.vmd.hash = this.modifyHash;
                    this.changeTemplate(getTemplate(vmd.templateType));
                    this.countPlayTime();
                }
            });
            const channelRef = this.$firebase.database().ref(this.channel_root_key);
            channelRef.get().then((snapshot) => {
                if (this.modifyChannel) {
                    return false;
                }
                snapshot.forEach((channels) => {
                    if (this.modifyChannel) {
                        return false;
                    }
                    let thisChannelKey = channels.key;
                    let thisChannel = channels.val();
                    if(thisChannel && thisChannel.vmd){
                        thisChannel.vmd.forEach((vmd) => {
                            if (this.modifyHash === vmd) {
                                this.modifyChannel = thisChannelKey;
                                //console.log('채널 있음 : ' + thisChannelKey);
                                return false;
                            }
                        });
                    }
                });
            });
        } else {
            this.tempVmdData[TEMPLATE.HOT_DEAL.code] = new HotDeal();
            this.tempVmdData[TEMPLATE.HALF_PRICE.code] = new HalfPrice();
            this.tempVmdData[TEMPLATE.EXPLORE.code] = new Explore();
            this.vmd = this.tempVmdData[TEMPLATE.HOT_DEAL.code];
            this.vmd.files = [null, null, null, null, null];
        }
    },
}
</script>

<style scoped>

</style>